dmx.Component('cookie-manager', {

    initialData: {
        data: {}
    },

    attributes: {},

    methods: {
        set: function(name, value, options) {
            this.setCookie(name, value, options);
        },

        remove: function(name, options) {
            options = options || {};
            options.expires = '1970-01-01T00:00:00Z';
            this.setCookie(name, '', options);
        }
    },

    render: function(node) {
        this.update();
    },

    update: function() {
        this.set('data', document.cookie.split(/;\s*/).reduce(function(data, cookie) {
            var pos = cookie.indexOf('=');
            data[decodeURIComponent(cookie.substr(0, pos))] = decodeURIComponent(cookie.substr(pos + 1));
            return data;
        }, {}));
    },

    setCookie: function(name, value, options) {
        if (!name || /^(?:expires|max\-age|path|domain|secure)$/i.test(name)) {
            return false;
        }

        options = options || {};
        options.path = options.path || '/';

        // escaping taken from:
        // https://github.com/js-cookie/js-cookie/blob/master/src/js.cookie.js
        name = encodeURIComponent(String(name));
		name = name.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent);
		name = name.replace(/[\(\)]/g, escape);

        value = encodeURIComponent(String(value));
        value = value.replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

        var cookie = name + '=' + value;

        if (options.expires) {
            if (typeof options.expires == 'number') {
                options.expires = Date.now() + (options.expires * 86400000);
            }
            cookie += '; expires=' + (new Date(options.expires)).toUTCString();
        }

        if (options.domain) {
            cookie += '; domain=' + options.domain;
        }

        if (options.path) {
            cookie += '; path=' + options.path;
        }

        if (options.secure) {
            cookie += '; secure';
        }

        document.cookie = cookie;

        dmx.requestUpdate();
    }

});
